
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tutu-primary: mat-palette($mat-indigo);
$tutu-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tutu-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$tutu-theme: mat-light-theme($tutu-primary, $tutu-accent, $tutu-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($tutu-theme);

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

$primary: #d93eba;
$body-bg: #fff;
$secondary: #8300d9;

@import '~bootstrap/scss/bootstrap.scss';
*/
