
html, body { height: 100%; }
body { margin: 0}

$primary: #119F94;;
$body-bg: #fff;
$secondary:#4D30AD;
$info:#999999;

@import '~bootstrap/scss/bootstrap.scss';

$body-font: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$heading-font:'Open Sans', sans-serif;
$body-color:#717477;
$heading-color:#0f2137;
$white-color:#ffffff;

body {
    color: $body-color;
    font-family: $body-font;
    line-height: 1.8;
    font-size: 16px;
    padding: 0;
    margin:0;
}

.site-button-link {
  color: $secondary;
  font-weight: bold;
}

.site-button-free {
  font-size: 14px;
}

.site-banner-title {
  font-size: 2.0rem;
  color: $secondary;
  font-weight: bold;
  text-align: left;
}

.site-banner-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-top: 20px;
  color: #000000;
}

.site-signin-img {
  background-image: url("./../img/site/signin.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 800px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

site-signin-text {
  color: black;
}

.dash-nav {

  .nav-link:hover {
    // color: $secondary;
    font-weight: bold;
  }

  .nav-link {
      color: white;
      font-size: 0.9rem;
  }

}


.dash-line {
  width: 100%;
}
